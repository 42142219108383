import { render, staticRenderFns } from "./terms-and-conditions.vue?vue&type=template&id=35044bfc&scoped=true&"
import script from "./terms-and-conditions.vue?vue&type=script&lang=js&"
export * from "./terms-and-conditions.vue?vue&type=script&lang=js&"
import style0 from "./terms-and-conditions.vue?vue&type=style&index=0&id=35044bfc&scoped=true&lang=css&"
import style1 from "./terms-and-conditions.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35044bfc",
  null
  
)

export default component.exports