<template>
  <div class="bg-white">
    <div class="text-justify text-wrap pa-10">
      <div>
        <div class="d-flex justify-space-between align-center mb-5">
          <div class="headline">
            Terms & Conditions
          </div>
        </div>

        <h5 class="mb-5 headline-bold" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          Membership conditions
        </h5>

        <h5 class=" headline-bold" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          About MICELOC:
        </h5>
        <div>
          MICELOC is a project of PW-BWS GmbH and the only search engine worldwide for combinations of hotel and event
          locations.<br />
          The project is operated by PW-BWS (AG München HRA 204436, in the following „We“ or „Us“)<br />
          The following conditions regulate your rights and obligations as a member with a hotel or event location at
          MICELOC.
        </div>
        <h5 class="mt-5 headline-bold text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          1. Start, duration and termination of membership
        </h5>
        <div>
          By submitting your membership application, you declare that you wish to join the MICELOC portal for
          professional (i.e. not private) and promotional purposes. The membership starts on the day of completion of
          the digital onboarding process and payment of the annual fee according to clause 3.<br />
          Each membership has a minimum term of twelve months. During this time a cancellation without important reason
          is excluded.<br />
          If the membership is not cancelled in writing at least three months before the end of the minimum term, the
          membership will be tacitly renewed for another twelve months. The date on which we receive the written notice
          of termination is decisive for the date of termination.<br />
          If you are in arrears with the payment of an annual fee in accordance with clause 3, we are entitled to
          terminate the contract without notice for good cause. A termination for other, important reasons remains
          unaffected by this.<br />
          In the event of termination for good cause, we expressly reserve the right to claim damages in accordance with
          the statutory provisions.<br /><br />
        </div>
        <h5 class="headline-bold text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          2. Services for members of MICELOC
        </h5>
        <div>
          The conclusion of a MICELOC membership entitles you to a digital presentation of a hotel or an event location
          on the search engine MICELOC.<br />
          The presentation will be created based on the information provided by you through the registration process.
          Any changes are only possible if the information provided is incorrect. MICELOC does not guarantee visibility
          to users or bookings by users of the site.<br /><br />
        </div>
        <h5 class="headline-bold text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          3. Admission fee, membership fee, terms of payment
        </h5>
        <div>
          The annual membership fee is 118.80 EUR excluding VAT and is due 12 months after the first application for
          membership.<br />
          In addition, a one-time admission fee of 69.00 EUR excluding VAT is due upon initial application.<br />
          All of the above fees are subject to value added tax at the statutory rate. Payment is made via our payment
          provider Stripe or Paypal. Please note the respective terms and conditions and privacy policies of the payment
          providers.<br />
          You can find these for Stripe here:
          <a @click="redirectToBlankPage('https://stripe.com/de/privacy')" class="font-500 text-underline"
            >https://stripe.com/de/privacy</a
          ><br />
          You can find these for Paypal here:
          <a
            @click="redirectToBlankPage('https://www.paypal.com/de/webapps/mpp/ua/useragreement-full')"
            class="font-500 text-underline custom-wrap-text"
            >https://www.paypal.com/de/webapps/mpp/ua/useragreement-full</a
          ><br />
          You confirm that the means of payment used by you is your own or you have the permission of the account holder
          to use it. The Means of Payment is subject to a validity check and approval by the provider of the Means of
          Payment.<br />
          We reserve the right to refuse payment at our reasonable discretion.<br />
          We also reserve the right to change the price of membership. Price changes will take place at the earliest
          thirty (30) days after membership. In the event of a price change, you have the right to terminate the
          contract for good cause with effect from the day the price change takes effect.<br /><br />
        </div>

        <h5 class="headline-bold text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          4. Non-transferability of membership rights
        </h5>
        <div>
          The rights acquired with the membership are not transferable to third parties. A transfer of the entire
          membership to a third party is only possible with our prior written consent.<br /><br />
        </div>

        <h5 class="headline-bold text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          5. Limitation of liability
        </h5>
        <div>
          We are generally not liable for damages of a member. This does not apply to liability for a breach of an
          essential contractual obligation and to liability for damages to the member resulting from injury to life,
          body or health, nor to damages resulting from an intentional or grossly negligent breach of duty by us, our
          legal representatives or vicarious agents. Essential contractual obligations are those whose fulfillment is
          essential for the proper execution of the contract and on whose compliance you can regularly rely.<br />
          Our liability to you on the basis of or in connection with the contractual services in the event of a slightly
          negligent breach of an essential contractual obligation in accordance with these conditions is limited to such
          damages as are typically foreseeable. A damage is „foreseeable“ if it was typically foreseeable at the time
          you accepted these terms and conditions.<br /><br />
        </div>

        <h5 class="headline-bold text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          6. Consent to the collection and use of data
        </h5>
        <div>
          By accepting these Terms of Use, you agree to the validity of the MICELOC Privacy Policy as amended from time
          to time.<br />
          See
          <a @click="$router.push('/privacy-policy')" class="font-500 color-primary">Privacy Policy</a>
        </div>

        <h5 class="mt-5 headline-bold text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          7. Final provisions
        </h5>
        <div>
          No oral agreements other than this contract have been made. Any amendment to this contract must be made in
          writing. This also applies to the cancellation of the written form clause itself.<br />
          If a provision of these terms and conditions is inadmissible or unenforceable, the validity of the remaining
          terms and conditions shall remain unaffected. If an inadmissible or unenforceable provision would become
          admissible or enforceable by deletion of a part, it is assumed that this part is deleted and the provision
          remains in force for the rest.<br />
          To the extent permitted by law, these terms and conditions shall be governed exclusively by German law. Place
          of jurisdiction is Munich.<br /><br />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    acceptPolicy() {
      this.dialog = false;
    },
    redirectToBlankPage(url) {
      window.open(url, "_blank");
    }
  }
};
</script>
<style scoped>
.bg-white {
  background-color: #fff;
}
.custom-wrap-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.no-justify-text {
  text-justify: none;
}
</style>
<style>
.headline {
  font-size: 25px !important;
  font-weight: 600 !important;
  line-height: 2rem;
  letter-spacing: 0.07em !important;
  font-family: "TT Norms" !important;
}
.headline-xs {
  font-size: 18px !important;
  font-weight: 600;
  line-height: 2rem;
  letter-spacing: 0.07em !important;
  font-family: "TT Norms" !important;
}
.headline-bold {
  font-size: 25px;
  font-weight: 600 !important;
  line-height: 2rem;
  letter-spacing: 0.06rem !important;
}
.f-20 {
  font-size: 20px !important;
}
</style>
